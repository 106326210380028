import React from "react";
import * as Yup from "yup";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { Form, Formik } from "formik";
import useGetDashboardRedirectInfo from "@/hooks/useGetDashboardRedirectInfo";
import { Profile } from "@/store/api/graphql/generated/api";
import { CURRENT_SCHOOL_YEAR } from "@hours/constants";
import useUpdateQueryParams from "@/hooks/useUpdateQueryParams";
import { useAuthModals } from "../../hooks/modals/useAuthModals";
import { addUserProperties, setAnalyticsUser } from "../../utils/analytics";
import { useAuthService, useTrackEvent, useMailerLiteService } from "../../services";
import { newEmailValidation, newNameValidation, newPasswordValidation } from "../../lib/FormValidation/User";
import { isBase64 } from "../../utils/helperfunctions";
import SignupFields from "./SignupFields";
import LegalSignupInfo from "./LegalSignupInfo";

export interface SignupFormValues {
  email: string;
  emailPref: boolean;
  name: string;
  password: string;
  subjectsAndExams?: {
    [key: string]: string[];
  };
}

interface SignupFormProps {
  source?: string;
  cta?: string;
}

// V2 of the signup form (this form) only requires the necessary information for account creation.
// The additional information like graduation year, subjects, etc., is moved to an onboarding flow.
// eslint-disable-next-line max-lines-per-function
export default function SignupFormV2({ source, cta }: SignupFormProps): JSX.Element {
  const router = useRouter();
  const query = useSearchParams();
  const pathname = usePathname();
  const { hideAuthModal } = useAuthModals();
  const { updateQueryParams } = useUpdateQueryParams(pathname, query);
  const { signUp } = useAuthService();
  const { trackEvent } = useTrackEvent();
  const { saveSubscriptor } = useMailerLiteService();

  const { isHomepage, dashboardPath } = useGetDashboardRedirectInfo();

  const isSavingSubjects = query?.["saving-subjects"] === "true";
  const subjects = query?.subjects?.split(",") || [];

  const onSubmit = async (formikValues: SignupFormValues) => {
    let values = { ...formikValues };
    if (isSavingSubjects) {
      values = { ...formikValues, subjectsAndExams: { [CURRENT_SCHOOL_YEAR]: subjects } };
    }

    try {
      const invoiceId = router?.query?.invoice_id as string;

      const { profile } = await signUp({
        ...values,
        invoiceId,
      });
      hideAuthModal();
      const referralCode = localStorage.getItem("referral-code");
      const decodedReferralCode = referralCode && isBase64(referralCode) ? atob(referralCode) : referralCode;
      if (profile) {
        const sourceProject = "library";

        saveSubscriptor(profile as unknown as Profile, cta, subjects, sourceProject, null, null);
      }
      setAnalyticsUser(profile);
      addUserProperties({
        "oauth-signup-strategy": "email-password",
        "referring-uuid": decodedReferralCode,
      });

      // track with amplitude
      trackEvent({
        category: "accounts",
        action: "account-created",
        label: "email-password-signup",
        other: { source },
      });

      const [basePath, existingSearchString] = dashboardPath.split("?");
      const queryParams = new URLSearchParams(existingSearchString);

      queryParams.set("action", "onboarding");

      const signupRedirectUrl = `${basePath}${queryParams.toString() ? `?${queryParams.toString()}` : ""}`;

      if (isHomepage) {
        router.push(signupRedirectUrl);
      } else {
        // used to show save subject paywall
        updateQueryParams({ newParams: [{ paramName: "saving-subjects", paramValue: "success" }] });
        hideAuthModal();
      }
    } catch (e) {
      // Error handled in useApiClient
    }
  };

  const initialLibraryValues = { email: "", name: "", password: "", emailPref: true };

  const libraryValidation = Yup.object().shape({
    email: newEmailValidation,
    name: newNameValidation,
    password: newPasswordValidation,
    emailPref: Yup.boolean(),
  });

  const buttonText = "Sign up";

  return (
    <>
      <Formik initialValues={initialLibraryValues} validationSchema={libraryValidation} onSubmit={onSubmit}>
        <Form>
          <SignupFields buttonText={buttonText} />
        </Form>
      </Formik>
      <LegalSignupInfo />
    </>
  );
}
