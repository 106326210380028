// originalButtonLabel is label of button clicked to open modal
// showLoginForm represents current form displaying, login or signup
// expandedModalType represents modal source if not a result of button click (account gate)

interface IGetMailerliteCTAProps {
  originalButtonLabel: string;
  showLoginForm: boolean;
  expandedModalType: string;
}

export const getMailerliteCTA = ({
  originalButtonLabel,
  showLoginForm,
  expandedModalType,
}: IGetMailerliteCTAProps): string => {
  // if you clicked the cheatsheet button you get cheatsheets no matter what
  if (originalButtonLabel?.match(/cheatsheet/i)) {
    return "cheatsheet";
  }

  // you should proceed as a gate or warning type ONLY if signing up for the first time through the account gate
  if ((expandedModalType === "gate" || expandedModalType === "warning") && !showLoginForm) {
    return expandedModalType;
  }

  // if user clicked login button but subsequently signed up, they should be counted as a new subscriber
  if ((originalButtonLabel?.match(/login/i) || originalButtonLabel?.match(/log in/i)) && !showLoginForm) {
    return "login";
  }

  // user logged in from login button or accountgate - they won't be added to any groups
  return "";
};
