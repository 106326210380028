import useIsLibraryApp from "@/hooks/Library/useIsLibraryApp";
import useGetDashboardRedirectInfo from "@/hooks/useGetDashboardRedirectInfo";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { getAmplitudeSessionId } from "@/utils/analytics";
import { ISubjectAutocompleteSubject } from "@/types";
import meta from "../meta";

type ThirdParty = "google" | "discord";

interface IUseLoginWithThirdParty {
  showLoginForm?: boolean;
  source?: string;
}

interface ILoginWithThirdParty {
  (
    thirdParty?: ThirdParty,
    subjects?: ISubjectAutocompleteSubject[],
    hsGraduationYear?: string,
    level?: string
  ): () => void;
}

const useLoginWithThirdParty = ({ showLoginForm, source }: IUseLoginWithThirdParty): ILoginWithThirdParty => {
  const router = useRouter();

  const searchParams = useSearchParams();
  const pathname = usePathname();
  const isLibraryApp = useIsLibraryApp();
  const isSignup = !showLoginForm;
  const { SSOredirect } = useGetDashboardRedirectInfo(isSignup);

  return (
      thirdParty?: ThirdParty,
      subjects?: ISubjectAutocompleteSubject[],
      hsGraduationYear?: string,
      level?: string
    ) =>
    () => {
      const isSavingSubjects = searchParams?.get("saving-subjects") === "true";
      const subjectsFromUrl =
        searchParams?.getAll?.("subjects")?.map((sub) => ({
          id: sub,
        })) || [];
      // for some reason keeping query params here leads to an "unexpected end of JSON input" error
      const sourceUrl = pathname?.split("?")[0] || "";
      const invoiceId = searchParams?.get("invoice_id") ?? "";

      // save redirect and signup strategy so it can be accessed after third party auth
      localStorage.setItem("oauth-redirect-url", SSOredirect);
      localStorage.setItem("oauth-signup-source", source);
      localStorage.setItem("oauth-signup-strategy", thirdParty);

      const analyticsSessionId = getAmplitudeSessionId();

      // Just sending array of subject name, id, emoji so that name field can be used by mailerlite, and full profile subjectsAndExams object can be reconstructed when needed based on CURRENT_SCHOOL_YEAR
      const redirectState = encodeURIComponent(
        JSON.stringify({
          subjects: isSavingSubjects ? subjectsFromUrl : subjects,
          source,
          isLibraryApp,
          sourceUrl,
          analyticsSessionId,
          hsGraduationYear,
          invoiceId,
          level,
        })
      );

      const backendRedirect = `${window.location.origin}/?state=${redirectState}`;

      router.push(`${meta.backendUrl}/auth/${thirdParty}/?redirect=${backendRedirect}`);
    };
};

export default useLoginWithThirdParty;
