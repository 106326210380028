import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import MuiFormControl, { FormControlProps as MuiFormControlProps } from "@mui/material/FormControl";
import MuiInputAdornment from "@mui/material/InputAdornment";
import { useField } from "formik";
import TextField from "@/components-modern/atoms/textField/TextField";
import React, { useState } from "react";
import { Button } from "@/components-modern/atoms/button/Button";
import { useSpecificTheme } from "@/hooks/useSpecificTheme";

export type PasswordFieldProps = MuiFormControlProps & {
  name: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
};

export default function PasswordField({
  name,
  onKeyPress,
  placeholder,
  ...props
}: PasswordFieldProps): JSX.Element {
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = useState(false);
  const hasError = Boolean(meta.touched && meta.error);
  const theme = useSpecificTheme();

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <MuiFormControl fullWidth size="small" variant="outlined" {...props}>
      <TextField
        placeholder={placeholder}
        label={placeholder}
        type={showPassword ? "text" : "password"}
        onKeyPress={onKeyPress}
        aria-describedby="password-helper-text"
        InputProps={{
          endAdornment: (
            <MuiInputAdornment position="end">
              <Button
                variant="text"
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}>
                <FontAwesomeIcon
                  size="xs"
                  icon={showPassword ? faEye : faEyeSlash}
                  style={{ width: "16px" }}
                />
              </Button>
            </MuiInputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { color: theme.palette.text.primary },
        }}
        error={hasError}
        id="password-input"
        {...field}
      />
    </MuiFormControl>
  );
}
