import { usePathname, useSearchParams } from "next/navigation";

interface IUseGetDashboardRedirectInfoReturn {
  isHomepage: boolean;
  dashboardPath: string;
  SSOredirect: string;
}

const useGetDashboardRedirectInfo = (isSignup = false): IUseGetDashboardRedirectInfoReturn => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  let params = "";
  const queryParams: Partial<{ action?: string }> = {};

  if (isSignup) {
    queryParams.action = "onboarding";
  }
  params = new URLSearchParams(queryParams).toString();

  const isHomepage = pathname === "/";
  const dashboardPath = `/my-stuff${params.length ? "?" : ""}${params}`;

  const nextSearch = new URLSearchParams(searchParams?.toString());
  ["apsignup", "thirdParty"].forEach((qp) => nextSearch.delete(qp));
  const libraryRedirect = `${pathname}?${nextSearch.toString()}`;

  // if on homepage, redirect to dashboard
  const redirectUrl = pathname === "/" ? dashboardPath : libraryRedirect;

  return { isHomepage, dashboardPath, SSOredirect: redirectUrl };
};

export default useGetDashboardRedirectInfo;
