import React from "react";
import Image from "next/legacy/image";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BoxProps as MuiBoxProps } from "@mui/material/Box";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import MuiToggleButton from "@mui/material/ToggleButton";
import { Button } from "@/components-modern/atoms/button/Button";

export enum SSOThirdParty {
  GOOGLE = "google",
  DISCORD = "discord",
}

const LoginButton = ({ sx = {}, ...props }: MuiButtonProps): JSX.Element => (
  <Button
    variant="contained"
    fullWidth
    sx={{
      "& > span": { marginLeft: "8px", fontSize: { xs: ".8rem", md: "1rem" }, textTransform: "capitalize" },
      m: 1,
      ...sx,
    }}
    {...props}
  />
);

export type LoginSignupPanelProps = MuiBoxProps & {
  isLogin?: boolean;
  source?: string;
  isExpanded?: boolean;
};

const SSO_LOGO_MAP = {
  google: "google.png",
  discord: "discord.svg",
};

interface SSOButtonProps {
  onClick?: () => void;
  type: SSOThirdParty;
}

export const SSOButton = ({ onClick, type }: SSOButtonProps): JSX.Element => {
  return (
    <LoginButton onClick={onClick}>
      <Image alt="" src={`/img/logos/${SSO_LOGO_MAP[type]}`} width={20} height={20} />
      <span>Continue with {type.charAt(0).toUpperCase() + type.slice(1)}</span>
    </LoginButton>
  );
};

export const EmailButton = ({ onClick }: { onClick?: () => void }): JSX.Element => {
  return (
    <LoginButton
      onClick={onClick}
      sx={{
        margin: "5px auto",
        mb: 2,
      }}>
      <FontAwesomeIcon icon={faEnvelope} style={{ height: "1.2rem", width: "1.2rem", marginRight: "5px" }} />
      <span>Continue with Email</span>
    </LoginButton>
  );
};

//  * Creates a custom toggle button since this appears
//  * to be the only toggle button like this we will need.
//  */
export const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    border: 0,
    color: theme.palette.text.primary,

    "&.MuiToggleButton-root": {
      borderRadius: 0,
      borderBottom: `2px solid transparent`,
      padding: 0,
      "&:focus": { outline: "none !important" },
      "&:hover": {
        background: "transparent",
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },

    "&:not(:first-of-type)": {
      marginLeft: theme.spacing(4),
    },

    "&.Mui-disabled": {
      border: 0,
    },
    "&.Mui-selected": {
      background: "transparent",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },
}));

enum FormType {
  login = "login",
  signup = "signup",
}

interface LoginSignupToggleProps {
  showLoginForm: boolean;
  handleChange: (event: React.SyntheticEvent, form?: string) => void;
  hideLoginButton?: boolean;
}

export const LoginSignupToggle = ({
  showLoginForm,
  handleChange,
  hideLoginButton = false,
}: LoginSignupToggleProps): JSX.Element => {
  return (
    <StyledToggleButtonGroup
      color="primary"
      value={showLoginForm ? FormType.login : FormType.signup}
      exclusive
      onChange={handleChange}
      sx={{ justifyContent: "center", width: "100%", marginBottom: 4 }}>
      <MuiToggleButton
        disableRipple
        value={FormType.signup}
        sx={{
          textTransform: "capitalize",
          fontSize: { xs: "1rem", md: "1.4rem" },
          pointerEvents: hideLoginButton ? "none" : "auto",
        }}>
        Sign up
      </MuiToggleButton>
      {!hideLoginButton && (
        <MuiToggleButton
          disableRipple
          value={FormType.login}
          sx={{ textTransform: "capitalize", fontSize: { xs: "1rem", md: "1.4rem" } }}>
          Log in
        </MuiToggleButton>
      )}
    </StyledToggleButtonGroup>
  );
};
