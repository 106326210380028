import React from "react";
import MuiBox from "@mui/material/Box";
import MuiStack from "@mui/material/Stack";
import MuiTypography from "@mui/material/Typography";
import { useField } from "formik";
import { useSwiper } from "swiper/react";
import { Button } from "@/components-modern/atoms/button/Button";
import TextField from "@/components-modern/atoms/textField/TextField";
import CheckboxField from "./CheckboxField";
import FormLabel from "../Design/FormLabel";
import PasswordField from "../Design/PasswordField";

// eslint-disable-next-line max-lines-per-function
const SignupFields = ({ buttonText = "Sign up" }: { buttonText?: string }): JSX.Element => {
  const swiper = useSwiper();
  const [nameField, nameMeta] = useField("name");
  const [emailField, emailMeta] = useField("email");
  const [passwordField, passwordMeta] = useField("password");

  const passwordComplete = passwordField?.value && !passwordMeta.error;
  const emailComplete = emailField?.value && !emailMeta.error;
  const nameComplete = nameField?.value && !nameMeta.error;
  const shouldContinue = passwordComplete && emailComplete && nameComplete;
  const nextButtonExists = buttonText?.toLowerCase() === "next";

  const clickNext = () => {
    return swiper.slideNext();
  };

  const onKeyPress = (e) => {
    // if key pressed is enter and all fields are complete, click next button if it exists
    if (e.key !== "Enter" || !nextButtonExists || !shouldContinue) return;

    e.preventDefault();
    clickNext();
  };

  return (
    <MuiStack spacing={3}>
      <MuiBox sx={{ marginBottom: "5px" }}>
        <FormLabel name="name" />
        <TextField
          id="name"
          InputLabelProps={{ shrink: false }}
          name="name"
          size="small"
          type="text"
          onKeyPress={onKeyPress}
          error={nameMeta.touched && Boolean(nameMeta.error)}
          helperText={nameMeta.touched ? nameMeta.error : null}
        />
      </MuiBox>
      <MuiBox sx={{ marginBottom: "5px" }}>
        <FormLabel name="email" />
        <TextField
          id="email"
          InputLabelProps={{ shrink: false }}
          name="email"
          type="email"
          size="small"
          onKeyPress={onKeyPress}
          error={emailMeta.touched && Boolean(emailMeta.error)}
          helperText={emailMeta.touched ? emailMeta.error : null}
        />
      </MuiBox>
      <MuiBox>
        <FormLabel name="password" htmlFor="password-input" />
        <PasswordField name="password" onKeyPress={onKeyPress} />
      </MuiBox>
      <CheckboxField
        name="emailPref"
        sx={{ transform: "scale(.8)" }}
        label={
          <MuiTypography sx={{ ml: 0.5, fontSize: "11px" }} variant="subtitle1">
            Send me new Fiveable updates, releases, and announcements
          </MuiTypography>
        }
      />
      <Button
        fullWidth
        variant="secondary"
        // needs this for box-shadow to show
        // normally would solve by changing overflow to visible
        // but that won't work because swiper 🫠
        sx={{ mb: 2 }}
        type="submit">
        {buttonText}
      </Button>
    </MuiStack>
  );
};

export default SignupFields;
