import React from "react";
import LoginForm from "./LoginForm";
import SignupFormV2 from "./SignupFormV2";

interface IEmailAccountFormProps {
  showLoginForm: boolean;
  mailerliteCTA: string;
  source: string;
}

const EmailAccountForm = ({ showLoginForm, mailerliteCTA, source }: IEmailAccountFormProps): JSX.Element => {
  if (showLoginForm) {
    return <LoginForm cta={mailerliteCTA} />;
  }

  return <SignupFormV2 source={source} cta={mailerliteCTA} />;
};

export default EmailAccountForm;
