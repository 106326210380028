import { MarketingSitePaths } from "@hours/constants";
import { Typography } from "@mui/material";
import Link from "@/components-modern/atoms/link/Link";

const LegalSignupInfo = (): JSX.Element => {
  return (
    <Typography variant="caption" mt={1.5}>
      By signing up, you accept Fiveable&apos;s{" "}
      <Link
        variant="textPrimary"
        href={MarketingSitePaths.TERMS}
        target="_self"
        linkStyles={{ fontSize: "inherit" }}>
        Terms of Use
      </Link>
      &nbsp;and&nbsp;
      <Link
        variant="textPrimary"
        href={MarketingSitePaths.PRIVACY}
        target="_self"
        linkStyles={{ fontSize: "inherit" }}>
        Privacy Policy
      </Link>
      &nbsp;and can confirm that you are 13 years of age or older.
    </Typography>
  );
};

export default LegalSignupInfo;
