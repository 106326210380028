import React from "react";
import MuiBox from "@mui/material/Box";
import { Formik, Form } from "formik";
import { useSearchParams, useRouter } from "next/navigation";
import * as Yup from "yup";
import useGetDashboardRedirectInfo from "@/hooks/useGetDashboardRedirectInfo";
import { Button } from "@/components-modern/atoms/button/Button";
import Link from "@/components-modern/atoms/link/Link";
import TextField from "@/components-modern/atoms/textField/TextField";
import { useAuthService, useTrackEvent } from "../../services";
import { useAuthModals } from "../../hooks/modals/useAuthModals";
import PasswordField from "../Design/PasswordField";
import { existingEmailValidation, existingPasswordValidation } from "../../lib/FormValidation/User";
import FormLabel from "../Design/FormLabel";

export interface LoginFormValues {
  email: string;
  password: string;
}
// eslint-disable-next-line max-lines-per-function
export default function LoginForm({ cta }: { cta?: string }): JSX.Element {
  const { hideAuthModal } = useAuthModals();
  const { login } = useAuthService();
  const { trackEvent } = useTrackEvent();
  const router = useRouter();
  const queryParams = useSearchParams();
  const { isHomepage, dashboardPath } = useGetDashboardRedirectInfo();

  const onSubmit = async ({ email, password }: LoginFormValues) => {
    try {
      // would be present if user is directed here from email after stripe purchase
      const invoiceId = queryParams?.get("invoice_id");

      const { redeemedInvoice, invoiceRedemptionAttempted } = await login({
        body: { username: email, password, invoiceId },
        cta,
      });
      trackEvent({ category: "accounts", action: "login", label: "email-password-login" });

      // TODO: Consolidate this code that is duplicated in these places:
      // - services/frontend/components/Auth/LoginForm.tsx
      // - services/frontend/components/Auth/SignupForm.tsx
      let params = "";
      if (invoiceRedemptionAttempted) {
        const newQueryParams = new URLSearchParams();
        newQueryParams.append("invoiceRedemptionAttempted", invoiceRedemptionAttempted);
        newQueryParams.append("redeemedInvoice", !!redeemedInvoice);
        params += "?";
        params += newQueryParams.toString();
      }

      if (isHomepage) router?.push(dashboardPath + params);
      hideAuthModal();
    } catch (e) {
      // Error handled in useApiClient
    }
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={Yup.object().shape({
        email: existingEmailValidation,
        password: existingPasswordValidation,
      })}
      onSubmit={onSubmit}>
      <Form>
        <MuiBox sx={{ marginBottom: "25px" }}>
          <FormLabel name="email" />
          <TextField id="email" name="email" type="email" size="small" />
        </MuiBox>
        <MuiBox sx={{ marginBottom: "5px" }}>
          <FormLabel name="password" htmlFor="password-input" />

          <PasswordField name="password" />
        </MuiBox>

        <Link variant="textPrimary" href="/password/forgot" onClick={hideAuthModal}>
          Forgot your password?
        </Link>

        <Button fullWidth type="submit" variant="secondary" sx={{ mt: 3 }}>
          Log in
        </Button>
      </Form>
    </Formik>
  );
}
