import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from "@mui/material/Checkbox";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import { useField } from "formik";
import React from "react";

export type CheckboxFieldProps = MuiCheckboxProps & {
  label: React.ReactElement;
  name: string;
};

export default function CheckboxField({ label, name, ...props }: CheckboxFieldProps): JSX.Element {
  const [field] = useField({ name, type: "checkbox" });
  return <MuiFormControlLabel control={<MuiCheckbox size="small" {...field} {...props} />} label={label} />;
}
