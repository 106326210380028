import React from "react";
import { InputLabel } from "@mui/material";
import { SxProps } from "@mui/system";

const FormLabel = ({ name, htmlFor, sx }: { name: string; htmlFor?: string; sx?: SxProps }): JSX.Element => {
  return (
    <InputLabel
      htmlFor={htmlFor || name}
      sx={(theme) => ({
        marginBottom: "10px",
        textTransform: "capitalize",
        fontWeight: "900",
        fontSize: { xs: ".8rem", md: ".9rem" },
        color: theme.palette.text.primary,
        ...sx,
      })}>
      {name}
    </InputLabel>
  );
};

export default FormLabel;
