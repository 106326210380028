"use client";

import React, { useState } from "react";
import { useSearchParams } from "next/navigation";
import useIsLibraryApp from "@/hooks/Library/useIsLibraryApp";
import { useSelector } from "react-redux";
import useLoginWithThirdParty from "@/hooks/useLoginWithThirdParty";
import { Typography, Divider, Box, BoxProps, Stack } from "@mui/material";
import LegalSignupInfo from "./LegalSignupInfo";
import EmailAccountForm from "./EmailAccountForm";
import {
  SSOButton,
  EmailButton,
  LoginSignupToggle,
  SSOThirdParty,
} from "../../components-modern/molecules/authButtons/AuthButtons";
import { getMailerliteCTA } from "./AuthUtils";

enum FormType {
  login = "login",
  signup = "signup",
}

export type LoginSignupPanelProps = BoxProps & {
  isLogin?: boolean;
  source?: string;
  isExpanded?: boolean;
};

// eslint-disable-next-line max-lines-per-function
export default function LoginSignupPanel({
  isLogin,
  source,
  isExpanded,
  ...props
}: LoginSignupPanelProps): JSX.Element {
  const isLibraryApp = useIsLibraryApp();
  const expandedModalType = useSelector((state: { expandedModalType?: string }) => state.expandedModalType);
  const originalButtonLabel = useSelector(
    (state: { authModalButtonLabel?: string }) => state.authModalButtonLabel
  );

  const searchParams = useSearchParams();
  const invoiceRedemptionAttempted = searchParams?.get("invoiceRedemptionAttempted");
  const isApsignup = searchParams?.get("apsignup") === "true";

  const [showLoginForm, setShowLoginForm] = useState(isLogin);
  const [showEmailForm, setShowEmailForm] = useState(false);

  // determine what mailerlite groups user should be added to based on what CTA they originally clicked or, in the case of the account gate, whether they are signing up for the first time or not
  const mailerliteCTA = isLibraryApp
    ? getMailerliteCTA({ originalButtonLabel, showLoginForm, expandedModalType })
    : "";

  const handleChange = (event: React.SyntheticEvent, form?: string) => {
    if (form !== null) {
      setShowLoginForm(form === FormType.login);
    }
  };

  const loginWithThirdParty = useLoginWithThirdParty({ showLoginForm, source: mailerliteCTA });

  if (isApsignup && isLibraryApp && !invoiceRedemptionAttempted) {
    return (
      <Box
        p={{ xs: 2, sm: 4 }}
        {...props}
        sx={{
          width: "450px",
          maxHeight: "100vh",
          maxWidth: "100%",
          minWidth: { md: !isExpanded && "450px" },
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          ...props?.sx,
        }}>
        <Box>
          <LoginSignupToggle showLoginForm={showLoginForm} handleChange={handleChange} hideLoginButton />
        </Box>
        <LegalSignupInfo />
      </Box>
    );
  }

  return (
    <Stack
      p={{ xs: 2, sm: 4 }}
      justifyContent="space-between"
      gap={1.5}
      {...props}
      sx={{
        width: isExpanded ? { md: "80%" } : "100%",

        minWidth: { md: "307px" },
        margin: "0 auto",
        height: "100%",
        ...props?.sx,
      }}>
      <Box>
        <LoginSignupToggle showLoginForm={showLoginForm} handleChange={handleChange} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <SSOButton onClick={loginWithThirdParty(SSOThirdParty.GOOGLE)} type={SSOThirdParty.GOOGLE} />
          {showLoginForm && (
            <SSOButton onClick={loginWithThirdParty(SSOThirdParty.DISCORD)} type={SSOThirdParty.DISCORD} />
          )}
        </Box>
        <Divider sx={{ my: { xs: 1, sm: 3 }, maxWidth: "100%" }}>
          <Typography variant="body2">OR</Typography>
        </Divider>
        <EmailButton onClick={() => setShowEmailForm(!showEmailForm)} />
        {showEmailForm && (
          <EmailAccountForm showLoginForm={showLoginForm} mailerliteCTA={mailerliteCTA} source={source} />
        )}
      </Box>
    </Stack>
  );
}
